@import '../../assets/styles/var';

.navigation {
  display: block;
  width: 100%;
  background-color: #F5F5F8;
  border-radius: 15px;
  padding: 30px;

  hr {
    border-top: 1px solid #D4D3E3;
    margin: 20px auto;
  }

  ul {
    padding: 0;

    li {
      list-style-type: none;

      a {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 35px;
        letter-spacing: 0.2px;
        color: @text-color;

        span {
          font-size: 16px;
          margin-right: 10px;
          width: 30px;
          color: #9D9EA9;
        }
      }
    }
  }
}
@primary-color: #3F3D56;@font-family: Montserrat;