@import './var';

body {
  h1 {
    font-size: 28px;
    font-weight: 600;
  }
  h3 {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 20px;
  }
}

p {
  margin-bottom: 0;
}
a {
  color: @text-color;
}
.default {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.default-content {
  padding: 30px;
}

.default-block {
  background-color: #F5F5F8;
  border-radius: 15px;
  padding: 25px;
}
.default-block-buttons {
  margin-bottom: 30px;
}

.private-title {
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
.right {
  text-align: right;
}

.user {
  .right {
    text-align: right;
  }
  .company {
    color: white;
    font-weight: 500;
    font-size: 12px;
    margin: 0;

    span {
      background-color: transparent;
      padding: 4px 12px;
      border-radius: 500px;
      border: 0.5px dashed rgba(255, 255, 255, 0.5);
    }
  }
  .count {
    color: white;
    line-height: 1.6;
    font-size: 12px;
    margin: 0;
  }
  .ant-btn-dashed {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    svg {
      color: white;
    }
  }
}

.task-title {
  padding: 20px;
  font-size: 12px;
}

.task {
  background-color: #f5f5f9;
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 10px 20px 20px;

  .progress {
    padding-bottom: 20px;
  }

  a {
    font-size: 12px;
    color: #1565C0;
    font-weight: 600;
    margin: 0;
    text-decoration: underline;
  }

  p {
    font-size: 12px;
    margin: 0;
    font-weight: 600;

    &.stat {
      margin: 0;

      span {
        display: inline-block;
        background-color: @primary-color;
        margin-right: 6px;
        border-radius: 500px;
        padding: 1px 7px;
        color: white;
        white-space: pre-wrap;
        margin-top: 5px;
      }
    }
  }
}
@primary-color: #3F3D56;@font-family: Montserrat;