.login-row {
  height: 100vh;
}

.private-login {
  background: #F5F5F8;
  border-radius: 10px;
  padding: 25px;

  h1 {
    font-size: 20px;
    font-weight: 800;
    line-height: 1.5;
  }

  h2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }
}
@primary-color: #3F3D56;@font-family: Montserrat;