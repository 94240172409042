header {
  background-color: #3F3D56;
  margin-bottom: 30px;

  .logo {
    padding: 15px;

    span {
      color: white;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      letter-spacing: 0.8px;
    }
  }

  .user {
    text-align: right;
  }
}
@primary-color: #3F3D56;@font-family: Montserrat;